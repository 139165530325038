import { Button, Flex, Greyscale } from 'glints-aries';
import { Typography as AriesTypography } from 'glints-aries/lib/@next/Typography/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileIcon = styled.img`
  border-radius: 14px;
  width: 65px;
`;

export const LinkButtonContainer = styled.a`
  width: 100%;
`;

export const Header = styled.div`
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #eeeeee;
`;

export const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  color: ${Greyscale.white};
  border-radius: 50%;
  background-color: #ed1c24;
  border: 1px solid #fafafa;
  position: absolute;
  top: 0;
  right: -2px;
  display: flex;
`;

export const Body = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #f8fafc;
`;

export const DownloadContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  align-content: center;
`;

export const QRCode = styled.div`
  grid-row: 1/3;
  margin-right: 8px;
  padding: 4px 4px 0 4px;
  border: 1px solid #13d6f6;
  border-radius: 4px;
`;

export const ChatButton = styled(Button).attrs({
  variant: 'solid-blue',
  block: true,
  isRoundedCorner: true,
  small: true,
})`
  width: 100%;
`;

export const Typography = styled(AriesTypography)`
  text-align: center;
`;
