import React, { ReactNode } from 'react';
import Link from 'next/link';

import { ROUTES } from 'src/common/routes';

type Props = {
  children?: ReactNode;
};

const ExpertDashboardLinkComponent = ({ children }: Props) => {
  return (
    <Link href={`/${ROUTES.expert}`}>
      <a>{children}</a>
    </Link>
  );
};

export const ExpertDashboardLink = React.memo(ExpertDashboardLinkComponent);
