import { Button, Greyscale, SecondaryColor } from 'glints-aries';
import styled from 'styled-components';

export const CTAButton = styled(Button)`
  text-transform: uppercase;
  color: ${Greyscale.white};
  button {
    padding: 8px 16px;
    line-height: 14px;
    font-size: 10px;
    font-weight: 700;
  }
`;

export const CTALink = styled.span`
  color: ${SecondaryColor.actionblue};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
`;
