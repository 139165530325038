import styled, { css } from 'styled-components';

export const unstyledButtonStyle = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

const UnstyledButton = styled.button.attrs(props => ({
  'data-cy': props['data-cy'],
}))`
  ${unstyledButtonStyle}
`;

export default UnstyledButton;
