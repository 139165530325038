import { Greyscale, SecondaryColor } from 'glints-aries';
import styled from 'styled-components';

import UnstyledButton from '../../../components/Button/UnstyledButton';

export const Button = styled(UnstyledButton)`
  position: relative;
  font-size: 20px;
  vertical-align: middle;
  svg {
    display: block;
    &:hover {
      fill: ${SecondaryColor.actionblue};
    }
  }
`;

export const Count = styled.div`
  width: 19px;
  height: 17px;
  font-size: 12px;
  font-weight: 600;
  color: ${Greyscale.white};
  border-radius: 50%;
  background-color: #ed1c24;
  position: absolute;
  top: -5px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
