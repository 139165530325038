import { ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const MobileOrTabletContainer = styled.div`
  @media (min-width: ${ScreenSize.desktopS}px) {
    display: none;
  }
`;

export const MobileContainer = styled.div`
  @media (min-width: ${ScreenSize.mobileL}px) {
    display: none;
  }
`;

export const DesktopOrTabletContainer = styled.div`
  @media (max-width: ${ScreenSize.tablet}px) {
    display: none;
  }
`;

export const DesktopContainer = styled.div`
  @media (max-width: ${ScreenSize.desktopS - 1}px) {
    display: none;
  }
`;
