import { ScreenSize } from 'glints-aries';

export class WindowUtils {
  static isMobile(): boolean {
    if (!window) return false;
    return Boolean(
      window.matchMedia(`(max-width: ${ScreenSize.mobileL}px)`).matches
    );
  }
}
