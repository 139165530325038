import { useEffect } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { getIsAuthenticated } from 'src/modules/Session/Selectors';

import { Expert } from '../Expert/models/Expert';
import { getActiveExpertAndExpertApplication } from '../graphql';
import { ActiveExpertAndExpertApplication } from '../models/ActiveExpertAndExpertApplication';
import { ExpertApplication } from '../models/ExpertApplication';

export const useGetActiveExpertAndExpertApplication = (): {
  expertApplication: Pick<ExpertApplication, 'status' | 'id'> | null;
  expert: Pick<Expert, 'id'> | null;
  error?: ApolloError | null;
  loading: boolean;
} => {
  const isLoggedIn = useSelector(getIsAuthenticated);

  const [query, { data, error, loading }] = useLazyQuery<{
    getActiveExpertAndExpertApplication: ActiveExpertAndExpertApplication;
  }>(getActiveExpertAndExpertApplication);

  useEffect(
    function fetchExpertApplciationStatus() {
      if (isLoggedIn) {
        query();
      }
    },
    [isLoggedIn, query]
  );

  if (!isLoggedIn) {
    return {
      expertApplication: null,
      loading: false,
      error: null,
      expert: null,
    } as const;
  }

  return {
    expertApplication:
      data?.getActiveExpertAndExpertApplication?.expertApplication,
    expert: data?.getActiveExpertAndExpertApplication?.expert,
    loading,
    error,
  } as const;
};
