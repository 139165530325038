import React from 'react';

import { useConfig } from 'src/common/hooks';
import { GoogleTagManager } from 'src/components/GoogleTagManager';

export enum GtmDataPosition {
  Header = 'header',
  Homepage = 'homepage',
}
type Props = {
  gtmDataPosition: GtmDataPosition;
};

export const ExpertRecruitmentProgramLink: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, gtmDataPosition }) => {
  const { EXPERTS_RECRUITMENT_PROGRAM_BASE } = useConfig();
  return (
    <GoogleTagManager
      tag="amplitude-expert_class-expert-recruiting"
      data-gtm-position={gtmDataPosition}
    >
      <a
        href={`${EXPERTS_RECRUITMENT_PROGRAM_BASE}/`}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    </GoogleTagManager>
  );
};
