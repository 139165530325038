import { gql } from '@apollo/client';

export const additionalInformationFragment = gql`
  fragment additionalInformationFragment on ExpertApplicationAdditionalInformation {
    id
    secondaryEmail
    selfDeclaredYearsOfExperience
  }
`;

export const userFragment = gql`
  fragment userFragment on User {
    id
    email
    firstName
    lastName
    phone
    portfolio {
      socialNetwork {
        LinkedIn
      }
    }
  }
`;

export const experienceFragment = gql`
  fragment experienceFragment on ExperienceOutput {
    id
    startDate
    title
    organization
    isPresent
    expertApplicationWorkExperience {
      id
      companySize
      jobLevel
    }
  }
`;

const rejectionReasonOutputFragment = gql`
  fragment rejectionReasonOutputFragment on ExpertApplicationRejectionReasonsOutput {
    selectedRejectionReasons {
      id
      translationKey
      defaultTranslation
    }
    otherRejectionReason {
      id
      reasonForRejection
    }
  }
`;

export const getActiveExpertApplication = gql`
  query getActiveExpertApplication {
    getActiveExpertApplication {
      id
      status
      dateOfSubmission
      additionalInformation {
        ...additionalInformationFragment
      }
      user {
        ...userFragment
      }
      workExperience {
        ...experienceFragment
      }
      rejectionReasons {
        ...rejectionReasonOutputFragment
      }
    }
  }
  ${additionalInformationFragment}
  ${userFragment}
  ${experienceFragment}
  ${rejectionReasonOutputFragment}
`;

export const getOrCreateUnApprovedExpertApplication = gql`
  mutation getOrCreateUnApprovedExpertApplication {
    getOrCreateUnApprovedExpertApplication {
      id
      status
      dateOfSubmission
      additionalInformation {
        ...additionalInformationFragment
      }
      workExperience {
        ...experienceFragment
      }
    }
  }
  ${additionalInformationFragment}
  ${experienceFragment}
`;

export const getActiveExpertAndExpertApplication = gql`
  query getActiveExpertAndExpertApplication {
    getActiveExpertAndExpertApplication {
      expert {
        id
      }
      expertApplication {
        id
        status
      }
    }
  }
`;

export const updateExpertApplicationPersonalInformation = gql`
  mutation updateExpertApplicationPersonalInformation(
    $data: UpdateExpertPersonalInformationInput!
  ) {
    updateExpertApplicationPersonalInformation(data: $data) {
      id
      user {
        ...userFragment
      }
      additionalInformation {
        ...additionalInformationFragment
      }
    }
  }
  ${additionalInformationFragment}
  ${userFragment}
`;

export const reviseExpertApplication = gql`
  mutation reviseExpertApplication {
    reviseExpertApplication {
      id
      status
    }
  }
`;
