import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { MainHeaderHeightContext } from 'src/modules/MainContainer/Context';

import * as S from './Dropdown.sc';

const isClickOnAnchor = (node, stopNode) => {
  if (node instanceof HTMLAnchorElement) return true;
  else if (node === stopNode) return false;
  else return isClickOnAnchor(node.parentNode, stopNode);
};

const Dropdown = ({
  content,
  onDropdownOpen,
  onDropdownClose,
  children,
  className,
  onOpenCallback,
}) => {
  const mainHeaderHeight = useContext(MainHeaderHeightContext);

  const [isOpen, setIsOpen] = useState(false);

  const openDropdown = () => {
    if (onDropdownOpen) onDropdownOpen();
    setIsOpen(true);
  };

  const closeDropdown = () => {
    if (onDropdownClose) onDropdownClose();
    setIsOpen(false);
  };

  const onClickToggle = () => {
    if (isOpen) closeDropdown();
    else {
      openDropdown();
      if (typeof onOpenCallback === 'function') onOpenCallback();
    }
  };

  const onClickOutsideDropdown = e => {
    if (isOpen && !e.currentTarget.contains(e.relatedTarget)) {
      closeDropdown();
    }
  };

  const onClickInsideDropdown = e => {
    if (isClickOnAnchor(e.target, e.currentTarget)) {
      closeDropdown();
    }
  };

  const isFunctionalContent = typeof content === 'function';

  return (
    <S.Container tabIndex="0" onBlur={onClickOutsideDropdown}>
      <div onClick={onClickToggle}>{children}</div>
      <S.Content
        top={mainHeaderHeight}
        isVisible={isOpen}
        onClick={onClickInsideDropdown}
        className={className}
      >
        <S.Pointer className={className} />
        {isFunctionalContent ? content(isOpen) : content}
      </S.Content>
    </S.Container>
  );
};

const withPreventFocus = WrappedButton => {
  const onFocus = e => {
    if (e.relatedTarget) {
      e.relatedTarget.focus();
    }
  };
  const Component = props => <WrappedButton onFocus={onFocus} {...props} />;
  Component.displayName = 'Dropdown.withPreventFocus';
  return Component;
};

Dropdown.withPreventFocus = withPreventFocus;

Dropdown.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  onDropdownOpen: PropTypes.func,
  onDropdownClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  onOpenCallback: PropTypes.func,
};

export default Dropdown;
