import {
  Button as GlintsButton,
  Dropdown,
  Greyscale,
  ScreenSize,
} from 'glints-aries';
import styled from 'styled-components';

import { MenuHeight } from 'src/styles/components/componentSize';

const underLineHoverStyle = `
  color: ${Greyscale.black};
  background-color: rgba(255, 255, 255, 1);
  &:after {
      transform: scaleX(1);
  }
`;

const underLine = `
    position: relative;
    &:hover {
      ${underLineHoverStyle}
    }
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: ${Greyscale.black};
        bottom: -5px;
        left: 0px;
        transition: all .2s ease-in-out;
        transform: scaleX(0);
    }
`;

export const Container = styled.nav`
  display: flex;
  align-items: center;
  padding: 20px 0 17px;
  margin: auto;
  background-color: ${Greyscale.white};
  height: ${MenuHeight.desktop}px;
`;

export const MenuText = styled.h3`
  color: ${Greyscale.black};
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin: auto;
  ${underLine};
  &:hover {
    background-color: transparent;
  }
`;

export const Button = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: ${Greyscale.black};
  cursor: pointer;

  span {
    ${underLine}
  }
`;

export const RightMenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-transform: uppercase;
  margin-left: auto;
`;

export const EmployersButton = styled(GlintsButton)`
  a {
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const VerticalDivider = styled.span`
  display: inline-block;
  width: 1px;
  height: 40px;
  margin: 0 15px;
  background-color: ${Greyscale.lightgrey};
`;

export const UserMenuItem = styled.div`
  margin-right: ${({ marginRight }) => marginRight || '30px'};
  font-size: 12px;
`;

export const MenuDropdown = styled(Dropdown)`
  text-transform: uppercase;
  font-size: 12px;
  .dropdown-content {
    padding: 0;
    margin: 1em 1.4em;
    font-weight: 500;
    background: transparent;
  }
  .dropdown-content:hover {
    background: transparent;
  }
  .dropdown-content:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #000000;
    bottom: -5px;
    left: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    transform: scaleX(0);
  }
  .dropdown-content:hover:after {
    transform: scaleX(1);
  }
  li {
    cursor: default;
    background: transparent;
    padding: 0;
    margin: 1em 1.4em;
  }
  li.active {
    background: transparent;
  }
`;

export const MenuItem = styled.div`
  &:first-of-type {
    margin-left: 30px;
  }
  margin-right: 30px;

  @media (min-width: ${ScreenSize.desktopS}px) and (max-width: ${ScreenSize.desktopM -
    1}px) {
    margin-right: 20px;
  }
`;

export const NewFeatureBadgeContainer = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 10px;
  font-size: 10px;
`;

export const CustomNavLink = styled.a`
  color: ${Greyscale.black};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin: auto;
  ${underLine};
  ${({ active }) => (active ? underLineHoverStyle : '')};

  &:hover {
    background-color: transparent;
  }
`;

export const Anchor = styled.a`
  color: ${Greyscale.black};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  margin: auto;
  ${underLine};
  &:hover {
    background-color: transparent;
  }
`;
