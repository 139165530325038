import React from 'react';
import { Loading } from 'glints-aries';
import { defineMessage, FormattedMessage } from 'react-intl';

import {
  ExpertRecruitmentProgramLink,
  GtmDataPosition,
} from 'src/components/links/ExpertRercuitmentProgramLink/ExpertRecruitmentProgramLink';
import { EBreakpoints, Media } from 'src/media';
import { ExpertApplicationLink } from 'src/modules/ExpertPlatform/Components/Links/ExpertApplicationLink';
import { ExpertDashboardLink } from 'src/modules/ExpertPlatform/Components/Links/ExpertDashboardLink';
import { useGetActiveExpertAndExpertApplication } from 'src/modules/ExpertPlatform/hooks/useGetActiveExpertAndExpertApplication';
import { ExpertApplicationStatus } from 'src/modules/ExpertPlatform/models/ExpertApplication';

import { CTAButton, CTALink } from './ExpertPlatformCTA.sc';

export const expertRecruitmentCTAMsg = defineMessage({
  id: 'nav_bar.create_your_class_button',
  defaultMessage: 'Create Your Class',
});

export const myClassCTAMsg = defineMessage({
  id: 'nav_bar.my_class_button',
  defaultMessage: 'My Class',
});

type CTAtext = {
  id: string;
  defaultMessage: string;
};

const StyledCTA = ({
  ctaText,
  isLoading = false,
}: {
  ctaText?: CTAtext;
  isLoading?: boolean;
}) => {
  return (
    <>
      <Media greaterThanOrEqual={EBreakpoints.desktopS}>
        <CTAButton small={true} block={true} variant="solid-blue">
          {isLoading ? <Loading /> : <FormattedMessage {...ctaText} />}
        </CTAButton>
      </Media>
      <Media lessThan={EBreakpoints.desktopS}>
        <CTALink>
          {isLoading ? <Loading /> : <FormattedMessage {...ctaText} />}
        </CTALink>
      </Media>
    </>
  );
};

export const ExpertPlatformCTA = () => {
  const { expertApplication, loading, expert } =
    useGetActiveExpertAndExpertApplication();
  if (loading) {
    return <StyledCTA isLoading={true} />;
  }
  const expertApplicationStatus = expertApplication?.status;
  const isAlreadyExpert = Boolean(expert);
  if (
    isAlreadyExpert ||
    expertApplicationStatus === ExpertApplicationStatus.approved
  ) {
    return (
      <ExpertDashboardLink>
        <StyledCTA ctaText={myClassCTAMsg} />
      </ExpertDashboardLink>
    );
  }
  if (expertApplicationStatus) {
    return (
      <ExpertApplicationLink>
        <StyledCTA ctaText={myClassCTAMsg} />
      </ExpertApplicationLink>
    );
  }
  return (
    <ExpertRecruitmentProgramLink gtmDataPosition={GtmDataPosition.Header}>
      <StyledCTA ctaText={expertRecruitmentCTAMsg} />
    </ExpertRecruitmentProgramLink>
  );
};
