import { Experience } from 'src/global/models';
import { MeValue } from 'src/reducers/user/me';

export enum ExpertApplicationStatus {
  editing = 'EDITING',
  reviewNeeded = 'REVIEW_NEEDED',
  reviewing = 'REVIEWING',
  approved = 'APPROVED',
  rejected = 'REJECTED',
  revising = 'REVISING',
  revoked = 'REVOKED',
}

export type ExpertApplicationStatusesForNotificationType =
  | typeof ExpertApplicationStatus.rejected
  | typeof ExpertApplicationStatus.approved;

export const ExpertApplicationCompanySize = {
  BETWEEN_0_TO_50: 'BETWEEN_0_TO_50',
  BETWEEN_50_TO_500: 'BETWEEN_50_TO_500',
  BETWEEN_500_TO_2000: 'BETWEEN_500_TO_2000',
  MORE_THAN_2000: 'MORE_THAN_2000',
} as const;

export type ExpertApplicationCompanySizeType =
  (typeof ExpertApplicationCompanySize)[keyof typeof ExpertApplicationCompanySize];

export type AdditionalInformation = {
  id: string;
  secondaryEmail?: string;
  selfDeclaredYearsOfExperience?: ExpertApplicationYearsOfExperienceType;
};

export type ExpertApplicationWorkExperience = {
  id: string;
  companySize: ExpertApplicationCompanySizeType;
  jobLevel: ExpertApplicationJobLevelType;
};

export type UserExperience = Pick<
  Experience,
  'id' | 'startDate' | 'organization' | 'isPresent' | 'title'
>;

export type ExpertApplicationExperienceOutput = {
  expertApplicationWorkExperience?: ExpertApplicationWorkExperience | null;
} & UserExperience;

export type ExpertApplicationOtherRejectionReason = {
  id: string;
  reasonForRejection: string;
};

export type ExpertApplicationRejectionReason = {
  id: string;
  translationKey: string;
  defaultTranslation: string;
};

export type ExpertApplicationRejectionReasonOutput = {
  selectedRejectionReasons: ExpertApplicationRejectionReason[];
  otherRejectionReason?: ExpertApplicationOtherRejectionReason | null;
};

export type ExpertApplication = {
  id: string;
  status: ExpertApplicationStatus;
  additionalInformation?: AdditionalInformation;
  user?: Partial<MeValue>;
  workExperience?: ExpertApplicationExperienceOutput;
  rejectionReasons?: ExpertApplicationRejectionReasonOutput | null;
  dateOfSubmission: string | null;
};

export const ExpertApplicationJobLevel = {
  NON_MANAGEMENT: 'NON_MANAGEMENT',
  JUNIOR: 'JUNIOR',
  MIDDLE: 'MIDDLE',
  SENIOR: 'SENIOR',
} as const;

export type ExpertApplicationJobLevelType =
  (typeof ExpertApplicationJobLevel)[keyof typeof ExpertApplicationJobLevel];

export const ExpertApplicationYearsOfExperience = {
  UNDER_3: 'UNDER_3',
  BETWEEN_3_TO_4: 'BETWEEN_3_TO_4',
  BETWEEN_4_TO_5: 'BETWEEN_4_TO_5',
  BETWEEN_5_TO_10: 'BETWEEN_5_TO_10',
  MORE_THAN_10: 'MORE_THAN_10',
} as const;

export type ExpertApplicationYearsOfExperienceType =
  (typeof ExpertApplicationYearsOfExperience)[keyof typeof ExpertApplicationYearsOfExperience];
