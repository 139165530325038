import { Greyscale, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Container = styled.div`
  text-transform: none;
  position: relative;
  &:focus {
    outline: none;
  }
`;

export const Content = styled.div`
  position: fixed;
  top: ${({ top }) => top}px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Greyscale.white};
  z-index: 100;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: background-color 0.2s ease-in-out;
  height: fit-content;
  max-height: 100%;
  overflow: auto;

  @media (min-width: ${ScreenSize.desktopS}px) {
    position: absolute;
    top: 40px;
    left: auto;
    right: -60px;
    bottom: auto;
    width: 360px;
    max-height: 594px;
    filter: drop-shadow(0 0 4px rgba(181, 181, 181, 0.5));
  }
`;

export const Pointer = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 10px 6px;
  border-color: transparent transparent ${Greyscale.lightgrey} transparent;
  position: absolute;
  top: -10px;
  right: 65px;
  &.search-dropdown {
    display: none;
  }

  @media (min-width: ${ScreenSize.mobileM}px) {
    right: 75px;
  }

  @media (min-width: ${ScreenSize.mobileL}px) {
    right: 80px;
  }

  @media (min-width: ${ScreenSize.tablet}px) {
    right: 87px;
  }

  @media (min-width: ${ScreenSize.desktopS}px) {
    right: 66px;
    border-color: transparent transparent ${Greyscale.white} transparent;
  }
`;
